import {
  Box,
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  HStack,
  Image,
  Stack,
  Tag,
  Text,
} from "@chakra-ui/react";
import {
  Dot,
  MessageCircle,
  MoreHorizontal,
  MoreVertical,
  ChevronDownIcon,
  Share2,
  ThumbsUp,
} from "lucide-react";
import React, { useState } from "react";
import LikeEmojiGroup from "@/components/common/likeEmojiGroup";
import CommentSlider from "@/components/postCards/commentSlider/commentSlider";
import { MdPlayArrow } from "react-icons/md";
import { ReactionPanel } from "@/components/common/reactionPanel";
import { getTimeAgo } from "@/utilities/utilityFunction";
import PostOption from "@/components/common/postOption";
import { useDisclosure } from "@chakra-ui/react";
import PostDetailsView from "@/components/postCards/postDetailsView";
import { readMoreFunc } from "@/utilities/utilityFunction";
import { CommentPanel } from "@/components/common/commentPanel";
import { useRouter } from "next/router";
import CommentThreadBox from "./commentThreadBox";

// import formatTitle from "@/components/common/FormatTitle";

const breakLines = (text) => {
  const lines = text?.split("\n");
  return lines?.map((line, index) => (
    <React.Fragment key={index}>
      {line}
      {index !== lines.length - 1 && <br />}{" "}
      {/* Add <br /> only if it's not the last line */}
    </React.Fragment>
  ));
};

const ImageFeedCard = ({
  name,
  uid,
  title,
  reactionCount,
  commentCount,
  createdAt,
  media,
  topComments,
  profilePic,
  followingCount,
  postId,
  userReaction,
  triggeredFrom,
  allowComment,
  isOpenComment,
  onToggleComment,
}) => {
  const router = useRouter();
  const [isExpanded, setIsExpanded] = useState(false);

  const {
    isOpen: isOpenPostDetails,
    onOpen: onOpenPostDetails,
    onClose: onClosePostDetails,
  } = useDisclosure();

  const isTitleSmall = title?.length < 86;
  const charLimit = 400; // Character limit before truncating

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const renderTitle = () => {
    if (isExpanded) {
      return (
        <>
          {breakLines(title)}
          <Text
            as="span"
            fontWeight="bold"
            cursor="pointer"
            onClick={handleToggleExpand}
          >
            see less
          </Text>
        </>
      );
    } else if (title?.length <= charLimit) {
      return breakLines(title);
    } else {
      return (
        <>
          {breakLines(title?.slice(0, charLimit))}...
          <Text
            as="span"
            fontWeight="bold"
            cursor="pointer"
            onClick={handleToggleExpand}
          >
            see more
          </Text>
        </>
      );
    }
  };

  return (
    <>
      <Box
        bg="#fff"
        mt="1"
        style={{
          borderTopLeftRadius: "9px",
          borderTopRightRadius: "9px",
        }}
      >
        <HStack
          align="center"
          justifyContent="space-between"
          padding={["3", null, "3"]}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box boxSize="43px">
              <Image
                objectFit="cover"
                width="100%"
                height="100%"
                className="rounded-3xl"
                src={profilePic}
                alt="Profile Image"
              />
            </Box>

            <Box ml="2">
              <Box display="flex" alignItems="center">
                <p
                  style={{
                    fontSize: "15px",
                    color: "#171717",
                    fontWeight: "650",
                    lineHeight: "15px",
                  }}
                >
                  {name}
                </p>
                {triggeredFrom == "group" && (
                  <>
                    <MdPlayArrow />
                    <p
                      style={{
                        fontSize: "14px",
                        color: "#171717",
                        fontWeight: "400",
                      }}
                    >
                      Posted in CAT 2021
                    </p>
                  </>
                )}
              </Box>

              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  marginRight: "3px",
                }}
              >
                <p
                  style={{
                    fontSize: "13px",
                    color: "#636363",
                  }}
                >
                  {getTimeAgo(createdAt)}
                </p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  viewBox="0 0 24 24"
                  height="24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-dot"
                >
                  <circle cx="12.1" cy="12.1" r="1" />
                </svg>
                <img
                  src="/lock.svg"
                  width={12}
                  style={{ marginBottom: "4px" }}
                />
              </div>
            </Box>
          </Box>

          <Box display="flex" alignItems="center" gap="4">
            <PostOption
              postUserId={uid}
              postId={postId}
              triggeredFrom={triggeredFrom}
              allowComment={allowComment}
            />
          </Box>
        </HStack>

        <Stack padding={["3", null, "0"]} paddingBottom={["3", null, "0"]}>
          <Text
            fontSize={"16px"}
            lineHeight="24px"
            style={{ padding: "0 15px 0px 15px" }}
          >
            {renderTitle()}
          </Text>
          <Box
            maxH="100vh "
            maxW="100vh"
            overflow="hidden"
            // onClick={() => {
            //   router.push(`/feed/post/${postId}`);
            // }} // onClick={onOpenPostDetails}
          >
            <Image
              alt="video"
              w="100%"
              h="100%"
              fit="contain"
              src={media?.[0]}
              cursor={"pointer"}
            />
          </Box>
          <HStack
            align="center"
            fontWeight="400"
            fontSize="14px"
            padding="5px 15px 5px 15px"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <LikeEmojiGroup postId={postId} />
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  color: "#455564",
                  paddingLeft: "5px",
                }}
              >
                {reactionCount}
              </span>
            </Box>
            <Box display="flex" alignItems="center">
              <Box pl="5">
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#455564",
                  }}
                >
                  {commentCount}
                </span>
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#8D96A5",
                    paddingLeft: "5px",
                  }}
                >
                  comments
                </span>
              </Box>
              <Dot color="#8D96A5" />
              <Box>
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#455564",
                  }}
                >
                  {followingCount}
                </span>
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#8D96A5",
                    paddingLeft: "5px",
                  }}
                >
                  following
                </span>
              </Box>
            </Box>
          </HStack>
        </Stack>
      </Box>
    </>
  );
};

export default ImageFeedCard;

import React, { useState } from "react";
import {
  Box,
  Divider,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Image,
  Stack,
  Tag,
  Text,
} from "@chakra-ui/react";
import {
  Dot,
  MessageCircle,
  MoreHorizontal,
  ChevronDownIcon,
  MoreVertical,
  Share2,
  ThumbsUp,
} from "lucide-react";
import LikeEmojiGroup from "@/components/common/likeEmojiGroup";
import { useRouter } from "next/navigation";
import { MdPlayArrow } from "react-icons/md";
import { getTimeAgo } from "@/utilities/utilityFunction";
import PostOption from "@/components/common/postOption";
import { useDisclosure } from "@chakra-ui/react";

// import formatTitle from "@/components/common/FormatTitle";
// import { HTMLConverter } from "@/components/common/FormatTitle";

const breakLines = (text) => {
  const lines = text?.split("\n");
  return lines?.map((line, index) => (
    <React.Fragment key={index}>
      {line}
      {index !== lines.length - 1 && <br />}{" "}
      {/* Add <br /> only if it's not the last line */}
    </React.Fragment>
  ));
};

const TextFeedCard = ({
  classId,
  name,
  uid,
  title,
  reactionCount,
  commentCount,
  createdAt,
  profilePic,
  media,
  postId,
  followingCount,
  reactionCountDetail,
  topComments,
  userReaction,
  triggeredFrom,
  allowComment,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const router = useRouter();
  const {
    isOpen: isOpenComment,
    onToggle: onToggleComment,
    onClose: onClose,
  } = useDisclosure();

  const isTitleSmall = title?.length < 86;
  const charLimit = 400; // Character limit before truncating

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const renderTitle = () => {
    if (isExpanded) {
      return (
        <>
          {breakLines(title)}
          <Text
            as="span"
            fontWeight="bold"
            cursor="pointer"
            onClick={handleToggleExpand}
          >
            see less
          </Text>
        </>
      );
    } else if (title?.length <= charLimit) {
      return breakLines(title);
    } else {
      return (
        <>
          {breakLines(title?.slice(0, charLimit))}...
          <Text
            as="span"
            fontWeight="bold"
            cursor="pointer"
            onClick={handleToggleExpand}
          >
            see more
          </Text>
        </>
      );
    }
  };

  return (
    <Box
      bg="#fff"
      mt="1"
      style={{
        borderTopLeftRadius: "9px",
        borderTopRightRadius: "9px",
      }}
    >
      <HStack
        align="center"
        justifyContent="space-between"
        padding={["3", null, "3"]}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box boxSize="43px">
            <Image
              objectFit="cover"
              width="100%"
              height="100%"
              className="rounded-3xl"
              src={profilePic}
              alt="Profile Image"
            />
          </Box>
          <Box ml="2">
            <Box display="flex" alignItems="center">
              <p
                style={{
                  fontSize: "15px",
                  color: "#171717",
                  fontWeight: "650",
                  lineHeight: "15px",
                }}
              >
                {name}
              </p>
              {triggeredFrom == "group" && (
                <>
                  <MdPlayArrow />
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#171717",
                      fontWeight: "400",
                    }}
                  >
                    Posted in CAT 2021
                  </p>
                </>
              )}
            </Box>

            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                marginRight: "3px",
              }}
            >
              <p
                style={{
                  fontSize: "13px",
                  color: "#636363",
                }}
              >
                {getTimeAgo(createdAt)}
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                viewBox="0 0 24 24"
                height="24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-dot"
              >
                <circle cx="12.1" cy="12.1" r="1" />
              </svg>
              <img src="/lock.svg" width={12} style={{ marginBottom: "4px" }} />
            </div>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" gap="4">
          <PostOption
            postUserId={uid}
            postId={postId}
            triggeredFrom={triggeredFrom}
            allowComment={allowComment}
          />
        </Box>
      </HStack>
      <Stack padding={["3", null, "0"]} paddingBottom={["3", null, "0"]}>
        <Text
          fontSize={isTitleSmall ? "21px" : "16px"}
          lineHeight="24px"
          letterSpacing={isTitleSmall && "0.2px"}
          fontWeight={isTitleSmall && "550"}
          style={{ padding: "0 15px 0px 15px" }}
        >
          {renderTitle()}
        </Text>
        <HStack
          align="center"
          fontWeight="400"
          fontSize="14px"
          padding="5px 15px 5px 15px"
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center">
            <LikeEmojiGroup
              userReaction={userReaction}
              reactionCountDetail={reactionCountDetail}
            />
            <span
              style={{
                fontSize: "14px",
                fontWeight: "600",
                color: "#455564",
                paddingLeft: "5px",
              }}
            >
              {reactionCount}
            </span>
          </Box>
          <Box display="flex" alignItems="center">
            <Box pl="5">
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  color: "#455564",
                }}
              >
                {commentCount}
              </span>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#8D96A5",
                  paddingLeft: "5px",
                }}
              >
                comments
              </span>
            </Box>
            <Dot color="#8D96A5" />
            <Box>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  color: "#455564",
                }}
              >
                53
              </span>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#8D96A5",
                  paddingLeft: "5px",
                }}
              >
                following
              </span>
            </Box>
          </Box>
        </HStack>
      </Stack>
    </Box>
  );
};

export default TextFeedCard;

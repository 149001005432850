import React from "react";
import { Box, HStack, Text, Stack, Divider, Image } from "@chakra-ui/react";
import { Dot } from "lucide-react";
import LikeEmojiGroup from "@/components/common/likeEmojiGroup";
import { getTimeAgo } from "@/utilities/utilityFunction";
import { useSelector } from "react-redux";
import { ReactionSelector } from "@/components/common/reactionSelector";

const breakLines = (text) => {
  const lines = text?.split("\n");
  return lines?.map((line, index) => (
    <React.Fragment key={index}>
      {line}
      {index !== lines.length - 1 && <br />}{" "}
      {/* Add <br /> only if it's not the last line */}
    </React.Fragment>
  ));
};

const CommentThreadBox = ({
  topComments,
  commentRef,
  postId,
  userReaction,
  isOpenComment,
  onToggleComment,
  triggeredFrom,
}) => {
  console.log("commentdata", topComments);
  const { _id: uid } = useSelector((state) => state.userData);
  const { _id: groupId } = useSelector((state) => state.groupData);

  return (
    <Box
      ref={commentRef}
      sx={{
        maxHeight: "400px", // Adjust the height as needed
        overflowY: "auto",
      }}
    >
      {topComments?.map((commentId, index) => {
        console.log("check ments", commentId);
        return (
          <React.Fragment key={index}>
            <Box id="comments-list" className="comments-list">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "start",
                }}
              >
                <Box boxSize="35px" minWidth={9}>
                  <Image
                    objectFit="cover"
                    width="100%"
                    height="100%"
                    className="rounded-full"
                    src={commentId?.uid?.profilePic}
                    alt="Profile Image"
                  />
                </Box>
                <Box ml={1}>
                  <Box
                    paddingX={3}
                    paddingY={2}
                    borderRadius={20}
                    backgroundColor={"#E8E8E8"}
                  >
                    <Box>
                      <p
                        style={{
                          fontSize: "13px",
                          color: "#171717",
                          fontWeight: "800",
                          lineHeight: "13px",
                        }}
                      >
                        {commentId?.uid?.name} (Top Contributor)
                      </p>
                    </Box>

                    <Text
                      fontSize="15px"
                      // pl="9"
                      lineHeight="20px"
                      // py="10px"
                      textAlign="justify"
                      mt={1.5}
                    >
                      {breakLines(commentId?.comment)}
                    </Text>
                  </Box>
                  <HStack
                    align="center"
                    justifyContent="space-between"
                    fontWeight="500"
                    fontSize="12px"
                    width="100%"
                    mb={2}
                  >
                    <Box
                      display={"flex"}
                      justifyContent="start"
                      alignItems="center"
                      gap={2}
                    >
                      <Text>1h</Text>
                      <ReactionSelector
                        postId={commentId?._id}
                        initialReaction={commentId?.userReaction}
                        triggeredFrom={triggeredFrom}
                        uid={uid}
                        groupId="none"
                        commentLevel="two"
                      />

                      <Text fontSize="12px" color="#455564">
                        Reply
                      </Text>
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <LikeEmojiGroup
                        userReaction={commentId?.userReaction}
                        reactionCountDetail={commentId?.reactionCountDetail}
                      />
                      <span
                        style={{
                          fontSize: "12px",
                          fontWeight: "600",
                          color: "#455564",
                          marginLeft: "5px",
                        }}
                      >
                        {commentId?.reactionCount}
                      </span>
                    </Box>
                  </HStack>
                </Box>
              </Box>
            </Box>
            {/* comment reply */}
            {commentId?.replies?.map((replyComment, replyIndex) => {
              return (
                <Box
                  key={replyIndex}
                  className="comments-list reply-list"
                  id="reply-list"
                  style={{}}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "80%",
                      marginLeft: "6%",
                    }}
                  >
                    <Box boxSize="35px" minWidth={9}>
                      <Image
                        objectFit="cover"
                        width="100%"
                        height="100%"
                        className="rounded-full"
                        src={replyComment?.uid?.profilePic}
                        alt="Profile Image"
                      />
                    </Box>

                    <Box ml="2">
                      <Box
                        paddingX={3}
                        paddingY={3}
                        borderRadius={20}
                        backgroundColor={"#E8E8E8"}
                      >
                        <Box>
                          <p
                            style={{
                              fontSize: "13px",
                              color: "#171717",
                              fontWeight: "800",
                              lineHeight: "13px",
                            }}
                          >
                            {replyComment?.uid?.name} (Top Contributor)
                          </p>
                        </Box>

                        <Text
                          fontSize="15px"
                          // pl="9"
                          lineHeight="20px"
                          // py="10px"
                          textAlign="justify"
                          mt={1.5}
                        >
                          {breakLines(replyComment?.replies)}
                        </Text>
                      </Box>
                      <HStack
                        align="center"
                        justifyContent="space-between"
                        fontWeight="500"
                        fontSize="12px"
                        width="100%"
                        mb={2}
                      >
                        <Box
                          display={"flex"}
                          justifyContent="start"
                          alignItems="center"
                          gap={2}
                        >
                          <Text>1h</Text>
                          <ReactionSelector
                            postId={commentId?._id}
                            initialReaction={commentId?.userReaction}
                            triggeredFrom={triggeredFrom}
                            uid={uid}
                            groupId="none"
                            commentLevel="two"
                          />

                          <Text fontSize="12px" color="#455564">
                            Reply
                          </Text>
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <LikeEmojiGroup
                            userReaction={replyComment?.userReaction}
                            reactionCountDetail={
                              replyComment?.reactionCountDetail
                            }
                          />
                          <span
                            style={{
                              fontSize: "12px",
                              fontWeight: "600",
                              color: "#455564",
                              marginLeft: "5px",
                            }}
                          >
                            {replyComment?.reactionCount}
                          </span>
                        </Box>
                      </HStack>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </React.Fragment>
        );
      })}
    </Box>
  );
};

export default CommentThreadBox;
